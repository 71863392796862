<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card
    v-if="!isLoading"
    class="max-h-content-container app-chat position-relative overflow-hidden text-sm"
    min-height="100%"
  >
    <!-- Navigation Drawer: Left Sidebar -->
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="374"
      touchless
      :right="$vuetify.rtl"
      mobile-breakpoint="sm"
      :temporary="$vuetify.breakpoint.xsOnly"
      absolute
    >
      <!--chat-left-sidebar-content-->
      <div class="chat-left-sidebar h-full">
        <div class="d-flex align-center pa-4">
          <v-badge
            bottom
            overlap
            dot
            bordered
            color="success"
            offset-x="11"
            offset-y="11"
            class="me-3 user-status-badge"
          >
            <v-avatar
              size="2.375rem"
              class="cursor-pointer v-avatar-light-bg success--text"
              @click="
                $emit('open-chat-user-profile-sidebar-content', true)
                $vuetify.breakpoint.smAndDown && $emit('close-left-sidebar')
              "
            >
              <v-img
                v-if="botOptions.botAvatarImg"
                :src="botOptions.botAvatarImg"
              ></v-img>
              <span v-else>BOT</span>
            </v-avatar>
          </v-badge>
          <strong>{{ $t('permiso.talking') }}</strong>
          <v-spacer></v-spacer>
          <v-tooltip
            top
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="
                  $router.push({ name: 'chatbot-talkings-archived' })
                  setChatActiveAdmin(null)
                "
              >
                <v-icon>
                  {{ icons.mdiArchive }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('lbl.talkingArchive') }}</span>
          </v-tooltip>
        </div>

        <v-divider></v-divider>

        <perfect-scrollbar
          :options="perfectScrollbarOptions"
          class="ps-chat-left-sidebar pt-7 pb-3 px-3"
        >
          <!--chat-contact-->
          <div
            v-for="contact in talkings"
            :key="`chat-${contact.uiiduser}`"
            class="chat-contact d-flex align-center pa-3 cursor-pointer rounded-lg"
            @click="setChatActive(contact)"
          >
            <v-badge
              bottom
              overlap
              dot
              bordered
              color="default"
              offset-x="11"
              offset-y="11"
              class="me-3 user-status-badge"
            >
              <v-avatar
                size="38"
                class="v-avatar-light-bg bg-static-white"
              >
                <v-img
                  v-if="botOptions.userAvatarImg"
                  :src="botOptions.userAvatarImg"
                ></v-img>
                <span v-else>U</span>
              </v-avatar>
            </v-badge>
            <div class="flex-grow-1 overflow-hidden">
              <p class="mb-0 font-weight-medium text--primary text-truncate">
                {{ contact.uiiduser }}
              </p>
              <p class="text--disabled mb-0 text-truncate">
                {{ contact.last_msg_user }}
              </p>
            </div>
            <div class="chat-meta d-flex flex-column align-self-stretch align-end">
              <p
                v-if="contact.cant_msg_no_read > 0"
                class="text-no-wrap mb-0"
              >
                <v-badge
                  color="error"
                  inline
                  :content="contact.cant_msg_no_read"
                  class="number-badge"
                >
                </v-badge>
              </p>
              <!--<p class="text-no-wrap mb-0">
                {{ formatDateToMonthShort(user.chat.lastMessage.time, { hour: 'numeric', minute: 'numeric' }) }}
              </p>
              <v-badge
                v-if="user.chat.unseenMsgs"
                color="error"
                inline
                :content="user.chat.unseenMsgs"
                class="number-badge"
              >
              </v-badge>-->
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </v-navigation-drawer>

    <div
      class="chat-content-area h-full d-flex flex-column"
      :style="contentStyles"
    >
      <!-- Active Chat -->
      <div
        v-if="chatActiveAdmin"
        class="h-full"
      >
        <!-- Navbar -->
        <div
          class="d-flex align-center justify-space-between px-5 py-4"
          style="height:72px"
        >
          <!-- Sidebar Toggler, Avatar & Name -->
          <div class="d-flex align-center">
            <v-badge
              bottom
              overlap
              dot
              bordered
              color="default"
              offset-x="11"
              offset-y="11"
              class="me-3 user-status-badge"
            >
              <v-avatar
                size="38"
                class="cursor-pointer v-avatar-light-bg"
              >
                <v-img
                  v-if="botOptions.userAvatarImg"
                  :src="botOptions.userAvatarImg"
                ></v-img>
                <span v-else>U</span>
              </v-avatar>
            </v-badge>
            <div class="d-flex flex-column">
              <p class="mb-0 text--primary font-weight-medium text-truncate">
                {{ chatActiveAdmin.uiiduser }}
              </p>
              <!--<span class="text--disabled text-xs">{{ activeChat.contact.role }}</span>-->
            </div>
          </div>

          <!-- Active Chat Actions -->
          <div class="d-flex align-baseline">
            <!--<v-icon class="me-3 cursor-pointer d-none d-sm-inline-flex">
              {{ icons.mdiPhone }}
            </v-icon>
            <v-icon class="me-3 cursor-pointer d-none d-sm-inline-flex">
              {{ icons.mdiVideoOutline }}
            </v-icon>
            <v-icon class="me-3 cursor-pointer d-none d-sm-inline-flex">
              {{ icons.mdiMagnify }}
            </v-icon> -->
            <v-tooltip
              top
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="archivarChat()"
                >
                  <v-icon>
                    {{ icons.mdiArchiveArrowDown }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.archive') }}</span>
            </v-tooltip>

            <v-icon
              class="cursor-pointer"
              @click="setChatActiveAdmin(null)"
            >
              {{ icons.mdiClose }}
            </v-icon>
          </div>
        </div>

        <v-divider></v-divider>

        <perfect-scrollbar
          ref="refChatLogPS"
          :options="perfectScrollbarOptions"
          class="ps-chat-log h-full"
        >
          <!--chat-log-->
          <div class="chat-log pa-5">
            <div
              v-for="(msgGrp, index) in chatActiveAdmin.talking"
              :key="index"
              ref="scroll"
              class="chat-group d-flex align-start"
              :class="[
                { 'flex-row-reverse': msgGrp.agent === 'bot' },
                { 'mb-8': chatActiveAdmin.talking.length - 1 !== index },
              ]"
            >
              <div
                class="chat-avatar"
                :class="msgGrp.agent === 'bot' ? 'ms-4' : 'me-4'"
              >
                <v-avatar :size="botOptions.avatarSize">
                  <v-img :src="msgGrp.agent === 'bot' ? botOptions.botAvatarImg : botOptions.userAvatarImg"></v-img>
                </v-avatar>
              </div>
              <div
                class="chat-body d-inline-flex flex-column"
                :class="msgGrp.agent === 'bot' ? 'align-end' : 'align-start'"
              >
                <!--TYPE=TEXT-->
                <span
                  v-if="msgGrp.type === 'text'"
                  class="py-3 px-4 elevation-1 text-message"
                  :class="[msgGrp.agent === 'bot' ? 'rounded-lg rounded-br-0' : 'rounded-lg rounded-bl-0']"
                  :style="
                    msgGrp.agent === 'bot'
                      ? `background-color: ${botOptions.msgBgBot};color: ${botOptions.msgColorBot};`
                      : `background-color: ${botOptions.msgBgUser};color: ${botOptions.msgColorUser};`
                  "
                >
                  <span
                    v-if="msgGrp.type === 'text'"
                    v-html="msgGrp.text"
                  ></span>
                </span>
                <!--TYPE=BUTTON-->
                <fragment v-else-if="msgGrp.type === 'button'">
                  <span
                    class="py-3 px-4 elevation-1 text-message rounded-lg rounded-br-0"
                    :style="`background-color: ${botOptions.msgBgBot};color: ${botOptions.msgColorBot};`"
                  >
                    <span>{{ msgGrp.text }}</span>
                  </span>
                  <v-btn
                    v-for="(btn, ind) in msgGrp.options"
                    :key="ind"
                    small
                    outlined
                    :color="botOptions.msgBgBot"
                    class="rounded-lg rounded-br-0 mb-3 mt-2"
                    @click="actionButton(btn)"
                  >
                    {{ btn.text }}
                  </v-btn>
                </fragment>
                <!--TYPE=AUDIO-->
                <fragment v-else-if="msgGrp.type === 'audio'">
                  <audio
                    v-for="(btn, ind) in msgGrp.options"
                    :key="ind"
                    :src="rutaPublic + btn.url"
                    controls
                  ></audio>
                </fragment>
                <!--TIME-->
                <div
                  v-if="msgGrp.agent === 'user'"
                  :class="{ 'text-right': msgGrp.agent === 'bot' }"
                >
                  <span
                    class="text-xs me-1 text--disabled caption"
                    :class="{ 'text-right': msgGrp.agent === 'bot' }"
                  >{{
                    msgGrp.time | moment('h:mm a')
                  }}</span>
                  <!--<v-icon
                    v-if="msgGrp.senderId !== chatData.contact.id"
                    size="16"
                    :color="resolveFeedbackIcon(msgGrp.messages[msgGrp.messages.length-1].feedback).color"
                  >
                    {{ resolveFeedbackIcon(msgGrp.messages[msgGrp.messages.length-1].feedback).icon }}
                  </v-icon>-->
                </div>
              </div>
            </div>
          </div>
        </perfect-scrollbar>

        <v-form
          autocomplete="off"
          @submit.prevent="sendMessage"
        >
          <v-text-field
            v-model="chatInputMessage"
            :placeholder="botOptions.inputPlaceholder"
            solo
            class="pb-5 px-5 flex-grow-0 msg-input"
            hide-details
            height="50"
            autofocus
          >
            <template #append>
              <!--<v-btn icon>
                <v-icon>{{ icons.mdiMicrophone }}</v-icon>
              </v-btn>
              <v-btn
                icon
                class="me-3"
              >
                <v-icon>
                  {{ icons.mdiAttachment }}
                </v-icon>
              </v-btn> -->
              <v-btn
                color="primary"
                elevation="0"
                type="submit"
                :disabled="!chatInputMessage"
                :icon="$vuetify.breakpoint.xsOnly"
              >
                <!--<template v-if="$vuetify.breakpoint.smAndUp">
                  Send
                </template> -->
                <template>
                  <v-icon>{{ icons.mdiSendOutline }}</v-icon>
                </template>
              </v-btn>
            </template>
          </v-text-field>
        </v-form>
      </div>

      <!-- Start Conversation -->
      <div
        v-else
        class="d-flex align-center justify-center flex-grow-1 flex-column"
      >
        <v-avatar
          size="109"
          class="elevation-3 mb-6 bg-card"
        >
          <v-icon
            size="50"
            class="rounded-0 text--primary"
          >
            {{ icons.mdiMessageTextOutline }}
          </v-icon>
        </v-avatar>
        <p class="mb-0 px-6 py-1 font-weight-medium text-lg elevation-3 rounded-xl text--primary bg-card">
          {{ $t('lbl.selectTalking') }}
        </p>
      </div>

      <v-dialog
        v-model="isDialogVisibleArchive"
        scrollable
        max-width="350px"
      >
        <v-card max-height="400px">
          <v-card-title>{{ $t('btn.archive') }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>{{ $t('msg.archiveChat') }}</v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="primary"
              outlined
              @click="isDialogVisibleArchive = !isDialogVisibleArchive"
            >
              {{ $t('btn.close') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              :loading="loading"
              @click="confirmArchive()"
            >
              {{ $t('btn.send') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
// eslint-disable-next-line object-curly-newline
import {
  mdiMenu,
  mdiMessageTextOutline,
  mdiPhone,
  mdiVideoOutline,
  mdiMagnify,
  mdiDotsVertical,
  mdiAttachment,
  mdiMicrophone,
  mdiSendOutline,
  mdiClose,
  mdiArchive,
  mdiArchiveArrowDown,
} from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

// eslint-disable-next-line import/no-unresolved
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    PerfectScrollbar,
  },
  setup() {
    const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 374 })

    return {
      // useChat
      // resolveAvatarBadgeVariant,

      // Layout
      isLeftSidebarOpen,
      contentStyles,

      // Filter
      avatarText,
    }
  },

  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      botOptions: {},
      icons: {
        mdiMenu,
        mdiMessageTextOutline,
        mdiPhone,
        mdiVideoOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiAttachment,
        mdiMicrophone,
        mdiSendOutline,
        mdiClose,
        mdiArchive,
        mdiArchiveArrowDown,
      },
      chatInputMessage: '',
      talkings: [],
      chatActive: null,
      isDialogVisibleArchive: false,
      loading: false,
      perfectScrollbarOptions: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        wheelSpeed: 0.7,
      },
    }
  },
  computed: {
    ...mapState({
      chatActiveAdmin: state => state.app.chatActiveAdmin,
    }),
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.$echo.channel('chats').listen('.chats', data => {
      // this.talkings = data.data
      this.talkings = data.data.sort((a, b) => b.cant_msg_no_read - a.cant_msg_no_read)
    })
  },
  created() {
    this.getConfig()
    this.getTalkings()
  },
  methods: {
    ...mapMutations(['setChatActiveAdmin']),
    getConfig() {
      this.axios
        .get('configuration-view/chatbot-setting', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.botOptions = res.data.data.data.data
            this.botOptions.botAvatarImg = process.env.VUE_APP_API_URL_PLUBLIC + this.botOptions.botAvatarImg
            this.botOptions.userAvatarImg = process.env.VUE_APP_API_URL_PLUBLIC + this.botOptions.userAvatarImg
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getTalkings() {
      this.axios
        .get('chatbot/talkings?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.talkings = res.data.data.sort((a, b) => b.cant_msg_no_read - a.cant_msg_no_read)
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    sendMessage() {
      const message = {
        agent: 'bot',
        type: 'text',
        update_id: Math.random()
          .toString(36)
          .substr(2, 9),
        text: this.chatInputMessage,
        options: [],
        visto: true,
        time: this.$moment().format(),
      }

      this.chatInputMessage = ''

      this.axios
        .post(`chatbot-front/${this.chatActiveAdmin.uiiduser}`, message)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          // console.log(res)
          this.scrollDown()
        })
    },
    setChatActive(contact) {
      this.chatActive = contact
      this.setChatActiveAdmin(contact)
      this.$echo.channel('chat').listen(`.chat.${contact.uiiduser}`, data => {
        this.setChatActiveAdmin(data.data)
        this.scrollDown()

        if (this.chatActive) {
          if (data.data.uiiduser === this.chatActive.uiiduser) {
            // MARCAR COMO LEIDO
            this.setVisto(contact.uiiduser)
          }
        }
      })
      this.scrollDown()

      // MARCACAR COMO LEIDO
      this.setVisto(contact.uiiduser)
    },
    scrollDown() {
      setTimeout(() => {
        const container = this.$refs.refChatLogPS
        container.$el.scrollTop = container.$el.scrollHeight
      }, 0)
    },
    actionButton(options) {
      window.open(options.value, '_blank')
    },
    setVisto(uiiduser) {
      this.axios
        .post(`chatbot-front/visto/${uiiduser}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          // console.log(res)
        })
    },
    archivarChat() {
      this.isDialogVisibleArchive = true
    },
    confirmArchive() {
      this.isDialogVisibleArchive = false
      if (this.chatActiveAdmin) {
        this.loading = true
        this.axios
          .post(
            `chatbot/talkings/archive/${this.chatActiveAdmin.uiiduser}`,
            { uiiduser: this.chatActiveAdmin.uiiduser },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.setChatActiveAdmin(null)
            this.loading = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/chat.scss';
</style>
